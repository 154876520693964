import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { useGate } from "statsig-react";

import { config } from "@atlas/app-config";
import { request } from "@atlas/lib/request";
import { Button } from "@atlas/ui/Button";
import { cn } from "@atlas/ui/helpers";

import { useAnalytics } from "../../context/analytics";
import { useCurrentUser } from "../../context/user";
import { CopyToClipboard } from "../CopyToClipboard";

export const ReferralLink = ({ className }: { className?: string }) => {
  const user = useCurrentUser();
  const { logEvent } = useAnalytics();
  const { value, isLoading } = useGate("show_referral_link");
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  if (!user?.statuteEligibility?.features?.canSendTakedowns) return null;
  if (!value || isLoading) return null;

  const inviteOthers = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    logEvent("Navbar Item Clicked", { item: "referral" });

    const res = await request(`${config.old_api_url}/user/account/referral_code`);
    setReferralCode(res.referralCode);

    // if available (on most mobile browsers) use the native sharing popup
    if (navigator && navigator.share) {
      navigator.share({
        title: "Sign up for Atlas",
        text: "Sign up for Atlas to protect you and your family under Daniel's Law",
        url: config.leo_signup_url + "?referral=" + encodeURIComponent(res.referralCode),
      });
    } else {
      // otherwise show a modal with the link
      setDialogIsOpen(true);
    }
  };

  const referralUrl = config.leo_signup_url + "?referral=" + encodeURIComponent(referralCode);

  return (
    <div className={cn("ReferralLink", className)}>
      <span className="desktop-only">
        <b>Want to help spread the word?</b>{" "}
        <a href="#" onClick={inviteOthers} className="ml-2">
          Invite LEOs to Atlas
        </a>
      </span>
      <small className="mobile-only">
        <a href="#" onClick={inviteOthers}>
          Refer Another Officer
        </a>
      </small>
      <Dialog open={dialogIsOpen} onClose={() => setDialogIsOpen(false)} className="">
        <DialogTitle id="alert-dialog-title">Spread the Word!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Invite your fellow New Jersey officers to join Atlas and help protect them and their
            families under Daniel's Law.
            <br />
            <br />
            Send them this link: <a href={referralUrl}>{referralUrl}</a>{" "}
            <CopyToClipboard text={referralUrl} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="large" onClick={() => setDialogIsOpen(false)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
