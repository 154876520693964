import { z } from "zod";

// Standard colors. Should match colors.scss
export const Color = {
  Red: "#F04438",
  Blue: "#116df8",
  Orange: "#F79009",
  Green: "#17B26A",
  Gray: "#83888c",
} as const;

export const ColorEnum = z.nativeEnum(Color);
export type ColorEnum = z.infer<typeof ColorEnum>;
