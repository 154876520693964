import { useRouter } from "next/router";
import React from "react";

import { config } from "@atlas/app-config";
import { Color } from "@atlas/lib/colors";
import { Tag } from "@atlas/ui/Tag";

import { useFeatureFlag } from "../../context/statsig";
import { useCurrentUser } from "../../context/user";
import { $api } from "../../lib/api/client";

import { ControlledDrawer } from "./DrawController";
import { Menu } from "./Menu";
import { Menus } from "./Menus";
import { UserSummary } from "./UserSummary";

const RosterBubble = () => {
  const { data } = $api.useQuery("get", "/roster/percent-enrolled");

  if (!data) return null;

  const percentEnrolled = Number(data.data.percentEnrolled ?? 0);

  if (percentEnrolled === undefined) return null;

  return (
    <Tag
      label={`${percentEnrolled}% Enrolled`}
      color={percentEnrolled <= 33 ? Color.Red : percentEnrolled <= 66 ? Color.Orange : Color.Green}
    />
  );
};

const CommonBubble = ({ count }: { count: number }) => {
  if (count === 0) return null;

  return (
    <div className="bg-red flex h-4 min-w-4 items-center justify-center rounded-full px-1 text-xs text-white">
      {count}
    </div>
  );
};

export const AlertBubble = () => {
  const { data } = $api.useQuery("get", "/alerts");
  const count = data?.data.unreadCount ?? 0;

  if (count === 0) return null;

  return <CommonBubble count={count} />;
};

const DarkwebBubble = () => {
  const { data } = $api.useQuery("get", "/alerts");
  const breachCount = (data?.data.newsfeedItems?.find((x) => x.key == "darkweb")?.props as any)
    ?.breachCount;
  const read = data?.data.readItems?.includes("darkweb");

  if (read || breachCount === 0) return null;

  return <CommonBubble count={breachCount} />;
};

export const Tab: { [key: string]: Menu } = {
  Support: {
    label: "Support",
    icon: "QuestionMark",
    dataAttr: "tab-support",
    onClick: () => window.Beacon("open"),
  },
  Logout: {
    label: "Log Out",
    href: `${config.old_api_url}/user/session/logout`,
    icon: "Logout-UntitledUI",
    dataAttr: "tab-logout",
  },
  DataBrokers: {
    label: "Data Brokers",
    icon: "Home-UntitledUI",
    href: "/user/data-brokers",
    dataAttr: "tab-databrokers",
  },
  Alerts: {
    label: "Alerts",
    icon: "Bell-UntitledUI",
    href: "/user/alerts",
    dataAttr: "tab-alerts",
    bubble: <AlertBubble />,
  },
  DarkWeb: {
    label: "Dark Web",
    icon: "Incognito",
    href: "/user/darkweb",
    dataAttr: "tab-darkweb",
    isVisible: (user) => user?.subscription.features.canViewDarkweb !== "none",
    bubble: <DarkwebBubble />,
  },
  Family: {
    label: "Family",
    icon: "Family-UntitledUI",
    dataAttr: "tab-family",
    href: "/user/family",
  },
  Roster: {
    label: "Roster",
    icon: "Work",
    href: "/user/roster",
    dataAttr: "tab-roster",
    isVisible: (user) => Boolean(user?.adminOfSuborgs && user?.adminOfSuborgs.length > 0),
    bubble: <RosterBubble />,
  },
  DesktopAccount: {
    label: "Account Settings",
    icon: "Gear-UntitledUI",
    href: "/user/account",
    hrefLinkOverride: "/user/account/profile", // link directly to first subpage
    dataAttr: "tab-account",
    nestedMenu: ["AccountProfile", "AccountTakedowns", "AccountSecurity", "AccountTerms"],
  },
  MobileAccount: {
    label: "Account",
    icon: "Gear-UntitledUI",
    href: "/user/account",
    dataAttr: "tab-account",
    nestedMenu: ["AccountProfile", "AccountTakedowns", "AccountSecurity", "AccountTerms"],
    drawer: {
      label: "Account Settings",
      anchor: "top",
      items: ["Logout"],
    },
  },
  AccountProfile: {
    label: "Your Profile",
    icon: "User-UntitledUI",
    dataAttr: "tab-profile",
    href: "/user/account/profile",
    isVisible: (user, router) => Boolean(router.asPath?.startsWith("/user/account")),
  },

  AccountTakedowns: {
    label: "Takedown Notices",
    icon: "Send",
    dataAttr: "tab-takedown-settings",
    href: "/user/account/takedown-settings",
    isVisible: (user, router) =>
      Boolean(
        user?.statuteEligibility.features.canSendTakedowns &&
          router.asPath?.startsWith("/user/account"),
      ),
  },
  AccountSecurity: {
    label: "Login and Security",
    icon: "Lock-UntitledUI",
    dataAttr: "tab-security",
    href: "/user/account/security",
    isVisible: (user, router) =>
      Boolean(!user?.isSso && router.asPath?.startsWith("/user/account")),
  },
  AccountTerms: {
    label: "Terms and Conditions",
    icon: "Gavel",
    dataAttr: "tab-terms",
    href: "/user/account/terms",
    isVisible: (user, router) => Boolean(router.asPath?.startsWith("/user/account")),
  },
  ServicesHeader: {
    label: "SERVICES",
    dataAttr: "tab-services",
    isHeader: true,
    isVisible: (user) => Boolean(user?.subscription.features.hasIdentityTheftInsurance),
  },
  AtlasMail: {
    label: "AtlasMail",
    icon: "Mail",
    href: "/user/atlasmail",
    hrefLinkOverride: "/user/atlasmail/all",
    dataAttr: "tab-atlasmail",
    isVisible: (user, router) =>
      Boolean(
        (user?.subscription.features.canViewAtlasMail &&
          user.statuteEligibility.features.canViewAtlasMail) ||
          router.asPath?.startsWith("/user/atlasmail"),
      ),
    nestedMenu: [
      "AtlasmailInbox",
      "AtlasmailStarred",
      "AtlasmailSent",
      "AtlasmailDrafts",
      "AtlasmailAssignments",
      "AtlasmailSpam",
    ],
  },
  IdTheft: {
    label: "Identity Theft",
    icon: "FingerPrint",
    href: "/user/identity-insurance",
    dataAttr: "tab-id-theft",
    isVisible: (user) => Boolean(user?.subscription.features.hasIdentityTheftInsurance),
  },
  LegalHeader: {
    label: "LEGAL",
    dataAttr: "tab-legal",
    isHeader: true,
    isVisible: (user) => Boolean(user?.statuteEligibility.features.canViewDanielsLawUi),
  },
  LEO: {
    label: "Daniel's Law (NJ)",
    icon: "Gavel",
    href: "/user/leo",
    dataAttr: "tab-leo",
    isVisible: (user) => Boolean(user?.statuteEligibility.features.canViewDanielsLawUi),
  },
  ASSIGNMENT_CONFIRMATIONS: {
    label: "Assignment Confirmations",
    icon: "Gavel",
    href: "/user/atlasmail/notices",
    dataAttr: "tab-assignment-confirmations",
    isVisible: (user) =>
      Boolean(user?.statuteEligibility.features.canViewDanielsLawUi && user.hasAssignmentClaims),
  },
  LEO_FAQ: {
    label: "FAQ",
    icon: "Faq",
    href: "/user/daniels-law-faq",
    dataAttr: "tab-faq",
    isVisible: (user) => Boolean(user?.statuteEligibility.features.canViewDanielsLawUi),
  },
  AtlasmailAll: {
    label: "All Mail",
    icon: "Mail",
    href: "/user/atlasmail/all",
    dataAttr: "tab-atlasmail-all",
    isVisible: (user, router) => Boolean(user && router.asPath?.startsWith("/user/atlasmail")),
  },
  AtlasmailInbox: {
    label: "Inbox",
    icon: "Inbox-UntitledUI",
    href: "/user/atlasmail/inbox",
    dataAttr: "tab-atlasmail-inbox",
    isVisible: (user, router) => Boolean(user && router.asPath?.startsWith("/user/atlasmail")),
  },
  AtlasmailStarred: {
    label: "Starred",
    icon: "StarOutline",
    href: "/user/atlasmail/starred",
    dataAttr: "tab-atlasmail-starred",
    isVisible: (user, router) => Boolean(user && router.asPath?.startsWith("/user/atlasmail")),
  },
  AtlasmailSent: {
    label: "Sent",
    icon: "Sent-UntitledUI",
    href: "/user/atlasmail/sent",
    dataAttr: "tab-atlasmail-sent",
    isVisible: (user, router) => Boolean(user && router.asPath?.startsWith("/user/atlasmail")),
  },
  AtlasmailDrafts: {
    label: "Drafts",
    icon: "File-UntitledUI",
    href: "/user/atlasmail/drafts",
    dataAttr: "tab-atlasmail-drafts",
    isVisible: (user, router) => Boolean(user && router.asPath?.startsWith("/user/atlasmail")),
  },
  AtlasmailAssignments: {
    label: "Assignment Confirmations",
    icon: "Gavel",
    href: "/user/atlasmail/notices",
    dataAttr: "tab-atlasmail-assignments",
    isVisible: (user, router) =>
      Boolean(user && router.asPath?.startsWith("/user/atlasmail") && user.hasAssignmentClaims),
  },
  AtlasmailSpam: {
    label: "Spam",
    icon: "Alert",
    href: "/user/atlasmail/spam",
    dataAttr: "tab-atlasmail-spam",
    isVisible: (user, router) => Boolean(user && router.asPath?.startsWith("/user/atlasmail")),
  },

  MobileMenu: {
    label: "Menu",
    icon: "Hamburger",
    dataAttr: "tab-more",
    drawer: {
      anchor: "bottom",
      label: "Menu",
      items: [
        "DataBrokers",
        "AtlasMail",
        "Alerts",
        "DarkWeb",
        "Family",
        "DesktopAccount",
        "LEO_FAQ",
        "Roster",
        "ServicesHeader",
        "IdTheft",
        "LegalHeader",
        "LEO",
        "ASSIGNMENT_CONFIRMATIONS",
      ],
    },
  },

  AccountSettings: {
    label: "Account Settings",
    icon: "Settings",
    href: "/user/account",
    dataAttr: "tab-account-settings",
    footer: true,
  },
  NewSupport: {
    label: "Support",
    icon: "Support",
    onClick: () => window.Beacon("open"),
    dataAttr: "tab-support",
    footer: true,
  },
  FAQ: {
    label: "FAQ",
    icon: "Faq",
    href: "/user/faq",
    dataAttr: "tab-faq",
    footer: true,
  },
  NewLogout: {
    label: "Logout",
    icon: "Leave",
    href: "/user/session/logout",
    dataAttr: "tab-logout",
    footer: true,
  },
};

export const DesktopMenu = () => {
  const user = useCurrentUser();
  const router = useRouter();
  const activeMenu = Object.values(Tab).find(
    (menu) => menu.href && router.asPath?.startsWith(menu.href),
  );
  const { value: newLeftNav } = useFeatureFlag("new_left_nav");

  return (
    <div
      className="hidden h-full w-80 shrink-0 overflow-y-auto border-r border-gray-200 bg-white p-4 pt-0 md:flex md:flex-col md:gap-4"
      data-attr="desktop-nav"
    >
      <UserSummary />
      <Menus menus={newLeftNav ? NewUserNavbarMenus : UserNavbarMenus} desktop user={user} />
      {activeMenu?.submenu?.items?.length && (
        <div className="border-r border-gray-200 bg-white">
          <h5 className="text-primary-dark border-b border-gray-200 p-4">
            {typeof activeMenu.label !== "function" &&
              (activeMenu.label || activeMenu.submenu.label)}
          </h5>
          <Menus menus={activeMenu.submenu.items} user={user} />
        </div>
      )}
    </div>
  );
};

const NewMobileMenuItems = ["Alerts", "DataBrokers", "AtlasMail", "DarkWeb", "Family"];

const OldMobileMenuItems = ["DataBrokers", "AtlasMail", "Alerts", "Family"];

export const MobileMenu = () => {
  const user = useCurrentUser();
  const router = useRouter();
  const { value: newLeftNav } = useFeatureFlag("new_left_nav");

  return (
    <ControlledDrawer user={user}>
      <Menus
        menus={
          router.asPath.startsWith("/user/atlasmail")
            ? [
                "AtlasmailAll",
                "AtlasmailInbox",
                "AtlasmailStarred",
                "AtlasmailDrafts",
                "MobileMenu",
              ]
            : newLeftNav
              ? NewMobileMenuItems
              : OldMobileMenuItems
        }
        user={user}
        mobile
      />
    </ControlledDrawer>
  );
};

export const NewUserNavbarMenus = [
  "DataBrokers",
  "AtlasMail",
  "Alerts",
  "DarkWeb",
  "Family",
  "Roster",
  "DesktopAccount",
  "LEO_FAQ",
  "ServicesHeader",
  "IdTheft",
  "LegalHeader",
  "LEO",
  "ASSIGNMENT_CONFIRMATIONS",
  "NewSupport",
  "AccountSettings",
  "FAQ",
  "NewLogout",
];

export const UserNavbarMenus = [
  "DataBrokers",
  "AtlasMail",
  "Alerts",
  "DarkWeb",
  "Family",
  "Roster",
  "DesktopAccount",
  "LEO_FAQ",
  "ServicesHeader",
  "IdTheft",
  "LegalHeader",
  "LEO",
  "ASSIGNMENT_CONFIRMATIONS",
];
