import createFetchClient from "openapi-fetch";
import createClient from "openapi-react-query";

import type { paths } from "@atlas/api/client";
import { config } from "@atlas/app-config";

const fetchClient = createFetchClient<paths>({
  baseUrl: config.api_url,
  credentials: "include",
});

export const $api = createClient(fetchClient);
