// WARNING : THIS FILE IS PUBLIC
// DO NOT PUBLISH SECRETS HERE

process.env.TZ = "UTC";

const environments = [
  "default",
  "local",
  "staging",
  "prod",
  "dev-anthony",
  "dev-arnaud",
  "dev-carl",
  "dev-david",
  "dev-nick",
] as const;

type envs = (typeof environments)[number];

interface Config {
  [key: string]: Partial<{ [key in envs]: any }>;
}

const appUrl: { [key in envs]: string } = {
  default: "",
  local: "http://localhost:3000",
  staging: "https://app.staging.dev-atlas.com",
  prod: "https://app.atlas.net",
  "dev-anthony": "https://app.anthony.dev-atlas.com",
  "dev-carl": "https://app.carl.dev-atlas.com",
  "dev-arnaud": "https://app.arnaud.dev-atlas.com",
  "dev-nick": "https://app.nick.dev-atlas.com",
  "dev-david": "https://app.david.dev-atlas.com",
};

const oldApiUrl: { [key in envs]: string } = {
  default: "/api",
  local: "http://localhost:3000/api",
  staging: "https://app.staging.dev-atlas.com/api",
  prod: "https://app.atlas.net/api",
  "dev-anthony": "https://app.anthony.dev-atlas.com/api",
  "dev-carl": "https://app.carl.dev-atlas.com/api",
  "dev-arnaud": "https://app.arnaud.dev-atlas.com/api",
  "dev-nick": "https://app.nick.dev-atlas.com/api",
  "dev-david": "https://app.david.dev-atlas.com/api",
};

const apiUrl: { [key in envs]: string } = {
  default: "/api",
  local: "http://localhost:8080",
  staging: "https://api.staging.dev-atlas.com",
  prod: "https://api.atlas.net",
  "dev-anthony": "https://api.anthony.dev-atlas.com",
  "dev-carl": "https://api.carl.dev-atlas.com",
  "dev-arnaud": "https://api.arnaud.dev-atlas.com",
  "dev-nick": "https://api.nick.dev-atlas.com",
  "dev-david": "https://api.david.dev-atlas.com",
};

const auth = Object.fromEntries(
  environments.map((env: envs) => [
    env,
    {
      admin: {
        passport_google_oauth20: {
          login: {
            apiUrl: `${apiUrl[env]}/auth/admin/google/login`,
            callbackUrl: `${apiUrl[env]}/auth/admin/google/login/callback`,
            authorizationUrl: `${appUrl[env]}/admin/accounts`,
            noAuthUrl: `${appUrl[env]}/admin`,
          },
        },
      },
      user: {
        passport_google_oauth20: {
          login: {
            apiUrl: `${apiUrl[env]}/auth/user/google/login`,
            callbackUrl: `${apiUrl[env]}/auth/user/google/login/callback`,
            authorizationUrl: `${appUrl[env]}/user/data-brokers`,
            noAuthUrl: `${apiUrl[env]}/auth/user/noauth`,
          },
          signup: {
            apiUrl: `${apiUrl[env]}/auth/user/google/signup`,
            callbackUrl: `${apiUrl[env]}/auth/user/google/signup/callback`,
            authorizationUrl: `${appUrl[env]}/onboard`,
            noAuthUrl: `${apiUrl[env]}/auth/user/nosignup`,
          },
        },
      },
    },
  ]),
);

const userHomePath = Object.fromEntries(
  environments.map((env: envs) => [env, `${appUrl[env]}/user/data-brokers`]),
);

const adminHomePath = Object.fromEntries(
  environments.map((env: envs) => [env, `${appUrl[env]}/admin/accounts`]),
);

const configs: Config = {
  cors: {
    default: true,
    local: false,
    prod: [/^https:\/\/(?:\w+\.)?atlas\.net$/, /^https:\/\/(?:\w+\.)?dsrcompliance\.com$/], // *.atlas.net and atlas.net
    staging: [/^https:\/\/(?:\w+\.)?staging\.dev\-atlas\.com$/], // *.staging.dev-atlas.com
    "dev-anthony": [/^https:\/\/(?:\w+\.)?anthony\.dev\-atlas\.com$/], // *.anthony.dev-atlas.com
    "dev-arnaud": [/^https:\/\/(?:\w+\.)?arnaud\.dev\-atlas\.com$/], // *.arnaud.dev-atlas.com
    "dev-carl": [/^https:\/\/(?:\w+\.)?carl\.dev\-atlas\.com$/], // *.carl.dev-atlas.com
    "dev-nick": [/^https:\/\/(?:\w+\.)?nick\.dev\-atlas\.com$/], // *.nick.dev-atlas.com
    "dev-david": [/^https:\/\/(?:\w+\.)?david\.dev\-atlas\.com$/], // *.david.dev-atlas.com
  },
  cookie_domain: {
    default: "",
    local: ".localhost",
    prod: ".atlas.net", // *.atlas.net and atlas.net
    staging: ".staging.dev-atlas.com", // *.staging.dev-atlas.com
    "dev-anthony": ".anthony.dev-atlas.com", // *.anthony.dev-atlas.com
    "dev-arnaud": ".arnaud.dev-atlas.com", // *.arnaud.dev-atlas.com
    "dev-carl": ".carl.dev-atlas.com", // *.carl.dev-atlas.com
    "dev-nick": ".nick.dev-atlas.com", // *.nick.dev-atlas.com
    "dev-david": ".david.dev-atlas.com", // *.david.dev-atlas.com
  },
  max_account_emails: {
    default: 20,
  },
  daily_user_scan_limit: {
    default: 100,
    prod: 5,
  },
  scan_interval_hours: {
    default: 7 * 24,
  },
  scan_duration_ms: {
    default: 15 * 1000,
  },
  user_home_path: userHomePath,
  admin_home_path: adminHomePath,
  session_expiration_hours: {
    default: 24 * 30,
  },
  admin_session_expiration_hours: {
    default: 24,
  },
  secrets: {
    default: { region: "us-east-1" },
    local: {
      region: "us-east-1",
      endpoint: "http://localhost:4566",
      accessKeyId: "access_key",
      secretAccessKey: "secret_key",
    },
  },
  ironclad: {
    default: {
      accessId: "1ea333df-7a4c-48fe-b9d5-4eb37f7c0845",
      forms: {
        daniels_law: { key: "embedded-njspba-eula", contractId: "220763" },
        regular: { key: "embedded-tucovqfeu", contractId: "223230" },
        florida: { key: "embedded-w1h1q1-oe", contractId: "270396" },
      },
    },
  },
  app_url: appUrl,
  api_url: apiUrl,
  old_api_url: oldApiUrl,
  leo_signup_url: {
    default: "",
    local: "http://localhost:3000/signup/leo",
    staging: "https://app.staging.dev-atlas.com/signup/leo",
    prod: "https://leo.atlas.net",
  },
  mixpanel: {
    prod: {
      access_key: "eca297c8f7238303465b9e312c8d88de",
      app_name: "atlas-web",
      is_debug: false,
    },
    staging: {
      access_key: "c59e4cdf21d579eb869b7ad44795ea36",
      app_name: "atlas-web",
      is_debug: false,
    },
    default: {
      access_key: "c59e4cdf21d579eb869b7ad44795ea36",
      app_name: "atlas-web",
      is_debug: true,
    },
  },
  hotjar: {
    default: {
      hjid: 3199622,
      enabled: false,
    },
    staging: {
      hjid: 3199014,
      enabled: true,
    },
    prod: {
      hjid: 3297784,
      enabled: true,
    },
  },
  places_index: {
    default: "atlasv4-local-place-index",
    local: "atlasv4-local-place-index",
  },
  org_pictures_url: {
    default: "https://atlasv4-prod-organization-pictures.s3.amazonaws.com",
    local: "http://localhost:4566/atlasv4-local-organization-pictures",
  },
  posthog: {
    default: {
      enabled: false,
    },
    staging: {
      enabled: false,
    },
    prod: {
      enabled: true,
    },
  },
  rollbar: {
    default: {
      enabled: false,
    },
    staging: {
      enabled: true,
    },
    prod: {
      enabled: true,
    },
  },
  auth,
  datadog: {
    default: {
      applicationId: "0e01262a-edce-433a-9b0d-8968048dd4be",
      clientToken: "pube093abe87a7705414d2d6abb70b70cf4",
      site: "datadoghq.com",
      service: "app",
      defaultPrivacyLevel: "mask-user-input",
      sampleRate: 100,
      sessionReplaySampleRate: 0,
      trackResources: true,
      trackLongTasks: true,
      trackInteractions: true,
      silentMultipleInit: true,
    },
  },
  chargebee: {
    default: {
      slackNotifications: false,
      enable: true,
      item_price_id: "atlas-premium-USD-Yearly",
      site: "crowdshield-test",
      publishableKey: "test_BViaDwCcuKTCcdUvcuOaT6v2WRzODoEagW8",
      discounts: {
        nj_njspba: {
          retired: "NJSPBA_RETIRED_ULPP",
          active: "NJSPBA_ACTIVE_ULPP",
          inactive: "NJSPBA_INACTIVE_ULPP",
        },
        nj_stfa: {
          retired: "NJ_STFA_RETIRED",
          active: "NJ_STFA_ACTIVE",
          inactive: "NJ_STFA_INACTIVE",
        },
        ny_suffolkpba: {
          active: "NY_SUFFOLKPBA_ACTIVE",
        },
        nj_pba105: {
          retired: "NJSPBA_105_RETIRED",
        },
      },
      planId: "atlas-premium",
    },
    staging: {
      enable: true,
      site: "crowdshield",
      item_price_id: "atlas-premium-staging-USD-Yearly",
      publishableKey: "live_HhZEJq92RMhx2KGs3imbOCpG21A2olCZ",
      discounts: {
        nj_njspba: {
          retired: "NJSPBA_RETIRED_ULPP_STAGING",
          active: "NJSPBA_ACTIVE_ULPP_STAGING",
          inactive: "NJSPBA_INACTIVE_ULPP_STAGING",
        },
        nj_stfa: {
          retired: "NJ_STFA_RETIRED_STAGING",
          active: "NJ_STFA_ACTIVE_STAGING",
          inactive: "NJ_STFA_INACTIVE_STAGING",
        },
        ny_suffolkpba: {
          active: "NY_SUFFOLKPBA_ACTIVE_STAGING",
        },
      },
      planId: "atlas-premium-staging",
    },
    prod: {
      slackNotifications: true,
      enable: true,
      site: "crowdshield",
      item_price_id: "atlas-premium-USD-Yearly",
      publishableKey: "live_HhZEJq92RMhx2KGs3imbOCpG21A2olCZ",
      discounts: {
        nj_njspba: {
          retired: "NJSPBA_RETIRED_ULPP",
          active: "NJSPBA_ACTIVE_ULPP",
          inactive: "NJSPBA_INACTIVE_ULPP",
        },
        nj_stfa: {
          retired: "NJ_STFA_RETIRED",
          active: "NJ_STFA_ACTIVE",
          inactive: "NJ_STFA_INACTIVE",
        },
        ny_suffolkpba: {
          active: "NY_SUFFOLKPBA_ACTIVE",
        },
        nj_pba105: {
          retired: "NJSPBA_105_RETIRED",
        },
      },
      planId: "atlas-premium",
    },
  },
  identityGuard: {
    default: {
      auth: "",
      url: "",
      offerCode: "local",
    },
    staging: {
      auth: process.env.IDENTITY_GUARD_KEY,
      url: "https://staging.api.identityguard.com/company/enrollments",
      offerCode: "jiazlqksvo",
    },
    prod: {
      auth: process.env.IDENTITY_GUARD_KEY,
      url: "https://api.identityguard.com/company/enrollments",
      offerCode: "fotizod3ar",
    },
  },
  segment: {
    default: {
      writeKey: "H3CIBClBY3LvSRYQq5CSGZcZNhTeD046",
      enabled: false,
    },
    staging: {
      writeKey: "H3CIBClBY3LvSRYQq5CSGZcZNhTeD046",
      enabled: true,
    },
    prod: {
      writeKey: "pZgFQrKoA36YPPSj4XO4TWN9qZ4hCxQZ",
      enabled: true,
    },
  },
  transactionalEmailService: {
    default: {
      source: "customerio",
    },
    local: {
      source: "smtp",
    },
  },
  skipTosStep: {
    default: process.env.NEXT_PUBLIC_SKIP_TOS_STEP === "true",
  },
  sms: {
    default: {
      enabled: false,
      campaignId: "cyc0MnvS",
    },
    prod: {
      enabled: true,
      campaignId: "cyc0MnvS",
    },
    staging: {
      enabled: true,
      campaignId: "cyc0MnvS",
    },
  },
  dataIntegrity: {
    default: {
      // atlasbot-testing slack webhook url
      alertWebhookUrl:
        "https://hooks.slack.com/services/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",
    },
    prod: {
      alertWebhookUrl:
        "https://hooks.slack.com/services/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",
    },
  },
  disableSignupsForOrgs: {
    default: true,
  },
  atlasmail_page_size: {
    default: 10,
  },
  etlSheetsExists: {
    prod: true,
    default: false,
  },
  replyEmail: {
    default: "support@atlas.net",
  },
};

// HACK PLEASE UNDO THIS
const hostname = typeof window !== "undefined" ? window.location.hostname : "";
export const env: envs =
  hostname === "app.atlas.net"
    ? "prod"
    : hostname === "app.staging.dev-atlas.com"
      ? "staging"
      : hostname.endsWith(".dev-atlas.com")
        ? (`dev-${hostname.split(".")[1]}` as envs)
        : hostname === "localhost"
          ? "local"
          : ((process.env.NEXT_PUBLIC_ENV || "local") as envs);

export const config = Object.fromEntries(
  Object.entries(configs).map(([key, value]) => [
    key,
    value[env] ? value[env] : (value as any).default, // cypress has issues using nullish collasceing operator with Es2022 as the target
  ]),
);
