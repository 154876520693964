import { useState, useEffect } from "react";

import { useMountEffect } from "@atlas/hooks/useMountEffect";
import type { AdminListFeatureFlagsResponse } from "@atlas/service/statsig";

import { useFeatureFlagOverrides, useOverrideFeatureFlag } from "../../context/statsig";
import { $api } from "../../lib/api/client";

export const FeatureToggleDropdown = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [features, setFeatures] = useState<AdminListFeatureFlagsResponse["flags"]>([]);
  const overrideFeatureFlag = useOverrideFeatureFlag();
  const overrides = useFeatureFlagOverrides();
  const { mutate } = $api.useMutation("get", "/feature-flags", {
    onSuccess: (response) => {
      setFeatures(response.data.flags);
    },
  });

  useMountEffect(() => {
    mutate({});
  });

  const toggleFeature = (feature: string) => {
    const currentFeature = features.find((f) => f.id === feature);
    if (currentFeature) {
      const newState = !(overrides[feature] !== undefined
        ? overrides[feature]
        : currentFeature.state);
      overrideFeatureFlag(feature, newState);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    const dropdown = document.getElementById("dropdown");
    if (dropdown && !dropdown.contains(event.target as Node)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  // Merge overrides into the features list
  const mergedFeatures = features.map((feature) => ({
    ...feature,
    state: overrides[feature.id] !== undefined ? overrides[feature.id] : feature.state,
  }));

  return (
    <div className="relative">
      <button
        className="text-red ml-4 rounded bg-white px-2 py-1 text-sm md:text-base"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        Toggle Features
      </button>
      {showDropdown && (
        <div
          id="dropdown"
          className="absolute right-0 z-50 mt-2 max-h-80 overflow-y-scroll rounded bg-white p-2 text-black shadow-lg"
        >
          {mergedFeatures.map((feature) => (
            <div key={feature.id} className="flex items-center p-4">
              <input
                id={feature.id}
                type="checkbox"
                checked={feature.state}
                onChange={() => toggleFeature(feature.id)}
                className="mr-2"
              />
              <label htmlFor={feature.id} className="select-none hover:cursor-pointer">
                {feature.name}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
