import { useRollbar, useRollbarPerson } from "@rollbar/react";
import { getCookie } from "cookies-next";
import { NextPage } from "next";
import { AppProps as NextAppProps } from "next/app";
import { useRouter } from "next/router";
// eslint-disable-next-line import/no-named-as-default
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { RefObject, useEffect, useRef, useState } from "react";
import { StatsigProvider } from "statsig-react";

import { config } from "@atlas/app-config";
import { CurrentEnvProvider } from "@atlas/hooks/useCurrentEnv";
import { useMountEffect } from "@atlas/hooks/useMountEffect";
import { triggerUserToastMessage } from "@atlas/lib/client/cookies";
import { trimUrlHashAndQuery } from "@atlas/lib/helper/url";
import { User } from "@atlas/service/account";

import { FeatureToggleDropdown } from "../components/FeatureFlags/FeatureToggleDropdown";
import { TopNav } from "../components/Nav/TopNav";
import { DesktopMenu, MobileMenu } from "../components/Nav/UserNavbar";
import { AnalyticsProvider, useAnalytics } from "../context/analytics";
import { IdentityContext } from "../context/identity";
import { FeatureFlagProvider } from "../context/statsig";
import { UserContext } from "../context/user";
import { $api } from "../lib/api/client";
import { initDatadog } from "../lib/datadog";
import { cn } from "../lib/tw";

interface PageWithTitle {
  title: string;
  noIndex?: boolean;
}

interface AppProps extends PageWithTitle {
  user: User | null;
  identity_id: string | null;
}

type AppPropsWithLayout = NextAppProps & {
  Component: NextPage;
  pageProps: AppProps;
  env: string;
  gitHash: string;
};

// Only initialize PostHog if we're in the browser and it's enabled for this env
if (typeof window !== "undefined" && config.posthog.enabled) {
  posthog.init("phc_j7U3CoBRHf6xM1IhYImPzGArRt78Lz5PCRFkXQXzlsd", {
    api_host: "https://app.posthog.com",
    // suppress logging of Posthog errors, which can happen when internet connection is lost
    // @see https://posthog.com/questions/offline-support
    on_xhr_error() {},
  });
}

const ImpersonationBanner = () => {
  const [impersonated, setImpersonated] = useState(false);

  useMountEffect(() => {
    if (getCookie("sessionType") === "impersonated") setImpersonated(true);
  });

  if (impersonated) {
    return (
      <div className="bg-red relative flex items-center justify-between p-4 text-white">
        <h1 className="text-base md:text-2xl">Currently Logged in as User</h1>
        <FeatureToggleDropdown />
      </div>
    );
  }

  return null;
};

const AppContent = ({
  loggedInPage,
  mainContainerRef,
  Component,
  pageProps,
}: {
  loggedInPage: boolean;
  mainContainerRef: RefObject<HTMLDivElement>;
  Component: NextPage;
  pageProps: AppProps;
}) => {
  return (
    <div className="flex h-screen flex-col">
      <ImpersonationBanner />
      {loggedInPage && <TopNav />}
      <div className="box-border flex grow overflow-hidden">
        {loggedInPage && <DesktopMenu />}
        <main className="flex w-full grow flex-col">
          <div id="main-content" className="grow overflow-y-auto" ref={mainContainerRef}>
            <div className={cn(loggedInPage && "max-w-full px-4 pb-32 pt-4 md:px-16")}>
              <Component {...(pageProps as any)} />
            </div>
          </div>
          {loggedInPage && (
            <div className="md:hidden">
              <MobileMenu />
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export function AppLayout({ pageProps, Component, env }: AppPropsWithLayout) {
  const { analytics, logNavigation } = useAnalytics();
  const rollbar = useRollbar();
  const containerRef = useRef<HTMLDivElement>(null);

  const router = useRouter();
  const [user, setUser] = useState<User | null>(pageProps.user);

  useRollbarPerson(user ?? {});

  useMountEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("referrer")) {
      queryParams.delete("referrer");
      router.replace({
        search: queryParams.toString(),
      });
    }
  });

  const {
    data: userData,
    isLoading: loadingUser,
    error: userLoadingError,
  } = $api.useQuery("get", "/user");

  useEffect(() => {
    if (loadingUser || userLoadingError || !userData) {
      return;
    }

    if (userData?.data.user) {
      // Allows us to update the user context via `mutate` anywhere in the app
      // (ie trigger a fetch of the latest user data)
      setUser(userData.data.user);
    }
  }, [userData, router, loadingUser, userLoadingError]);

  const hasLoggedNavigation = useRef<boolean>(false);
  const mainContainerRef = useRef<HTMLDivElement>(null);

  function logOnPageLoad() {
    // Initial log on page load
    logNavigation(trimUrlHashAndQuery(router.asPath));

    const handleRouteChange = (route: string, { shallow }) => {
      // Only if the page actually changed: https://nextjs.org/docs/routing/shallow-routing
      // eg ignore #params changing at the end of URLs
      if (!shallow) {
        logNavigation(trimUrlHashAndQuery(route));
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  if (!hasLoggedNavigation.current && router.events) {
    logOnPageLoad();
    hasLoggedNavigation.current = true;
  }

  const initHelpScout = () => {
    try {
      if (window.Beacon && user) {
        window.Beacon("identify", {
          name: user.full_name,
          "account-id": user.account_id,
          "posthog-url": `https://app.posthog.com/person/${user.account_id}#activeTab=sessionRecordings`,
          "hotjar-url": `https://insights.hotjar.com/sites/${config.hotjar.hjid}/playbacks?sort_by=-created&filters={"AND":[{"DAYS_AGO":{"created":365}},{"EQUAL":{"user_attributes.str.user_id":"${user.account_id}"}}]}`,
          "metabase-url": `https://crowdshield.metabaseapp.com/dashboard/401-account-review-draft?account_id=${user.account_id}`,
          "admin-url": `${config.app_url}/admin/accounts/${user.account_id}`,
        });
        window.Beacon("prefill", {
          email: user.email,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  if (!hasLoggedNavigation.current && router.events) {
    logOnPageLoad();
    hasLoggedNavigation.current = true;
  }

  useMountEffect(() => {
    initDatadog();
    initHelpScout();
  });

  // Initialize our analytics streams
  useMountEffect(() => {
    // disable all user analytics on admin and internal users
    if (user?.is_internal) return;

    // check if we already set a cio id
    const cioId = typeof window !== "undefined" && window.localStorage.getItem("_cio_id");

    if (!cioId) {
      // check if there's a cio id in the query params
      if (router.query._cio_id && typeof window !== "undefined") {
        window.localStorage.setItem("_cio_id", router.query._cio_id as string);
      }
    }

    if (user && config.segment.enabled) {
      analytics.identify(user.account_id, {
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        organization: user.organization?.org_id,
        internal: user.is_internal,
        premium: user.subscription.isPremium,
        subscriptionType: user.subscription_type,
      });
    }

    if (config.posthog.enabled && posthog && user?.account_id) {
      posthog.identify(user.account_id, {
        email: user.email,
        name: user.full_name,
        organization: user.organization?.org_id,
      });
    }
  });

  useMountEffect(() => {
    try {
      triggerUserToastMessage();
    } catch (err) {
      rollbar.error("Cannot trigger user toast message", err);
    }
  });

  useEffect(() => {
    if (mainContainerRef.current && containerRef.current) {
      mainContainerRef.current.scrollTo({
        top: 0,
        left: 0,
      });

      containerRef.current.scrollTo({
        top: 0,
        left: 0,
      });
    }
  }, [router.asPath]);

  const loggedInPage = router.asPath.startsWith("/user");

  return (
    <CurrentEnvProvider env={env}>
      <AnalyticsProvider>
        <PostHogProvider client={posthog}>
          <UserContext.Provider value={user}>
            <IdentityContext.Provider
              value={pageProps.identity_id ? { id: pageProps.identity_id } : null}
            >
              <StatsigProvider
                user={user ? { ...user, userID: user.account_id } : {}}
                sdkKey="client-cbzfXY36xnsPSKS2NEm8IsE4J4L9lBCiZNJua4uRGE8"
                options={{ environment: { tier: process.env.NEXT_PUBLIC_ENV } }}
              >
                <FeatureFlagProvider>
                  <AppContent
                    loggedInPage={loggedInPage}
                    mainContainerRef={mainContainerRef}
                    Component={Component}
                    pageProps={pageProps}
                  />
                </FeatureFlagProvider>
              </StatsigProvider>
            </IdentityContext.Provider>
          </UserContext.Provider>
        </PostHogProvider>
      </AnalyticsProvider>
    </CurrentEnvProvider>
  );
}
