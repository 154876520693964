import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { ToastContainer } from "react-toastify";

import { config } from "@atlas/app-config";
import { AppAbility } from "@atlas/lib/authorization/adminAbility";
import { useAbility } from "@atlas/lib/authorization/context";
import { cn } from "@atlas/ui/helpers";
import { Icon } from "@atlas/ui/Icon";

interface NavItem {
  title: string;
  icon: string;
  path: string;
  dataAttr?: string;
  align?: "left" | "right";
  surface?: "desktop-only" | "mobile-only";
  canSee: (ability: AppAbility) => boolean;
}

const ACCOUNTS_ADMIN: NavItem = {
  title: "Accounts",
  icon: "User",
  path: "/admin/accounts",
  canSee: (ability) => ability.can("read", "Accounts Tab"),
};

const DATABROKERS_ADMIN: NavItem = {
  title: "Manual Removals",
  icon: "DataBrokers",
  path: "/admin/manual-removals",
  canSee: (ability) => ability.can("read", "Data Brokers Tab"),
};

const DARKWEB_ADMIN: NavItem = {
  title: "Dark Web",
  icon: "BadActor",
  path: "/admin/darkweb",
  canSee: (ability) => ability.can("read", "Dark Web Tab"),
};

const WAITLIST_ADMIN: NavItem = {
  title: "Waitlist",
  icon: "AddUser",
  path: "/admin/waitlist",
  canSee: (ability) => ability.can("read", "Waitlist Tab"),
};

const LOGOUT: NavItem = {
  title: "Logout",
  icon: "LockOpen",
  path: `${config.old_api_url}/admin/session/logout`,
  dataAttr: "tab-admin-logout",
  align: "right",
  canSee() {
    return true;
  },
};

const ROSTER: NavItem = {
  title: "Roster",
  icon: "Work",
  path: "/admin/roster",
  canSee: (ability) => ability.can("read", "Roster Tab"),
};

const DATA_INTEGRITY: NavItem = {
  title: "Data Integrity",
  icon: "CheckCircle",
  path: "/admin/data-integrity",
  canSee: (ability) => ability.can("read", "Data Integrity"),
};

const TAKEDOWNS: NavItem = {
  title: "Takedowns",
  icon: "Mail",
  path: "/admin/takedowns",
  canSee: (ability) => ability.can("read", "Takedown Jobs"),
};

const TEMPLATES: NavItem = {
  title: "Templates",
  icon: "Faq",
  path: "/admin/templates",
  canSee: (ability) => ability.can("read", "Template Tab"),
};

export const ADMIN_NAV = [
  ACCOUNTS_ADMIN,
  WAITLIST_ADMIN,
  DATABROKERS_ADMIN,
  DARKWEB_ADMIN,
  ROSTER,
  DATA_INTEGRITY,
  TAKEDOWNS,
  TEMPLATES,
  LOGOUT,
];

const NavBarItem = ({
  menu,
  href,
  selected,
}: {
  menu: NavItem;
  href: string;
  selected: boolean;
}) => {
  const { surface, dataAttr, icon, title } = menu;
  return (
    <Link
      key={href}
      href={href}
      className={cn(
        "text-gray-dark flex items-center gap-2.5 rounded-lg px-6 py-4 no-underline hover:opacity-80",
        selected && "text-white",
        surface,
        "hover:bg-[#83888c2f] hover:text-white",
        selected && "bg-primary-darkest text-white",
      )}
      data-attr={dataAttr}
    >
      <Icon icon={icon} className="size-5 dark:text-gray-400 dark:group-hover:text-white" />
      <span className="text-nowrap text-sm font-medium dark:text-gray-400 dark:group-hover:text-white">
        {title}
      </span>
    </Link>
  );
};

const toastContainer = (
  <span>
    <ToastContainer position="top-center" autoClose={2400} hideProgressBar={true} />
  </span>
);

export const AdminNavbar = () => {
  const { pathname } = useRouter();
  const ability = useAbility();

  if (!pathname.startsWith("/admin")) return null;
  if (pathname.endsWith("/admin")) return <> {toastContainer} </>;

  const navItems = ADMIN_NAV.map((menu) => {
    const selected = pathname.startsWith(menu.path);
    if (!menu.canSee(ability)) return null;
    return <NavBarItem key={menu.title} menu={menu} href={menu.path} selected={selected} />;
  });

  const homePath = pathname.startsWith("/admin") ? "/admin" : "/";
  return (
    <>
      <Head>
        <link rel="icon" href="/img/favicon/dark.svg" />
      </Head>
      <div className="bg-primary-darker flex flex-col">
        <a href={homePath} className="mb-4 px-8 pt-4">
          <Icon icon="logo" className="h-8 w-auto cursor-pointer text-white hover:opacity-80" />
        </a>
        <nav className="flex gap-2 overflow-x-auto px-8 pb-2">{navItems}</nav>
      </div>
      {toastContainer}
    </>
  );
};
